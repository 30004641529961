<template>
  <CDropdown >
    <CDropdownToggle placement="bottom-end" class="py-0" style="color: #fff;" :caret="false">
      <CAvatar :src="avatar_1" size="md" />
       {{user?.name+' '+user?.last_name }}
    </CDropdownToggle>
    <CDropdownMenu>
      <CDropdownItem @click="logout" style="cursor: pointer;" > 
        <CIcon icon="cil-lock-locked" /> 
        Cerrar Sesión 
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>

import avatar_1 from '@/assets/images/avatar_1.png';
import { useUserStore } from '@/plugins/store';

export default {
  name: 'AppHeaderDropdownAccnt',
  data () {
    return { 
      avatar_1,
      user: null
    }
  },
  setup() {
  },
  created(){
    const userStore = useUserStore()
    this.user = userStore.getUser
  },
  methods: {
     logout(){

      useUserStore().logout()
      .finally(() => {
        location.href= '#/pages/login'
      })
    },
  } 
}
</script>
