import { defineStore } from "pinia";
import axios from 'axios'
import jwtToken from "@/plugins/jwt/jwt-token"; // Assuming jwtToken is already defined elsewhere
import { useLocalStorage } from '@vueuse/core'


export const useUserStore = defineStore("user", {
  state: () => ({
    user: useLocalStorage('user', null),
  }),

  actions: {
    async fetchUser() {
      const res = await axios.post("/user");
      this.user = JSON.stringify(res.data.user);
    },
    async signUp(name, last_name, email, password, password_confirmation) {
      const res = await axios.post("/auth/register",{
        name,
        last_name,
        email,
        password,
        password_confirmation,
        device_name: navigator.userAgent
      });
  
      this.user = res.data.user ? JSON.stringify(res.data.user) : null;
      jwtToken.setToken(res.data.access_token) || null; // Set token only if it exists
  
      return res;
    },
    async signUpProvider(name, email, image, auth_provider) {
      const res = await axios.post("/auth/provider/login",{
        name,
        email,
        image,
        auth_provider
      });
  
      this.user = res.data.user ? JSON.stringify(res.data.user) : null;
  
      return res;
    },
    async signIn(email, password) {
      const res = await axios.post("/auth/login",{
        email,
        password,
        device_name: navigator.userAgent
      });
  
      this.user = res.data.user ? JSON.stringify(res.data.user) : null;
      jwtToken.setToken(res.data.access_token) || null; // Set token only if it exists
  
      return res;
    },
    async editProfile(data) {
      const res = await axios.post("/auth/update", data);
  
      this.user = res.data ? JSON.stringify(res.data.data) : null;
      console.log(res.data.data);
      return res;
    },
    async logout() {
      const res = await axios.post("/auth/logout");
      this.user = null;
      return res;
    },
  },

  getters: {
    getUser(state) {
      return JSON.parse(state.user);
    },
  },
});

export const useDocumentStore = defineStore("document", {
  state: () => ({
    document: useLocalStorage('document', null),
  }),

  actions: {
    async setDocument(value) {
      this.user = JSON.stringify(value);
      return value;
    },
    async remove() {
      this.document = null;
      return true;
    },
  },

  getters: {
    getDocument(state) {
      return JSON.parse(state.document);
    },
  },
});