import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import SimpleLayout from '@/layouts/SimpleLayout'

const ConfigPage = () => import('@/views/Config/ConfigPage')
const DocumentPage = () => import('@/views/Document/DocumentPage')
const SignaturePage = () => import('@/views/Signature/SignaturePage')
const SignPage = () => import('@/views/Sign/SignPage')
const Signature = () => import('@/views/Successful/Signature')
const Sign = () => import('@/views/Successful/Sign')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: 'signatures',
        name: 'Firmas',
        component: {
        render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Firmas ',
            meta: { label: 'Firmas' },
            component: SignaturePage
          },  
        ],
      },

      {
        path: 'document',
        name: 'Documentos',
        component: {
        render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Documentos ',
            meta: { label: 'Documentos' },
            component: DocumentPage
          },  
        ],
      },      
      {
        path: 'config',
        name: 'Configuración',
        meta: { label: 'Configuración' },
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Configuración ',
            meta: { label: 'Configuración' },
            component: ConfigPage
          },
        ]
      },
      
    ],
  },
  {
    path: '/sign/:uuid/:description',
    name: 'Sign',
    component: SimpleLayout,
    children: [
      {
        path: '',
        name: 'Firmar Documento ',
        meta: { label: 'Firmar Documento' },
        component: SignPage
      },
    ] 
  },
  {
    path: '/successful_sign',
    name: 'Firma Exitoso',
    meta: { label: 'Firma Exitoso' },
    component: Sign
  },
  {
    path: '/successful_signature',
    name: 'Envio Exitoso',
    meta: { label: 'Envio Exitoso' },
    component: Signature
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
